/**
 * Since we can't switch to the app router yet, this is a fallback file for the pages router
 * See https://pxr-tech.atlassian.net/browse/PB-6306 for more details
 */
import { GetPropsReturn, GetServerProps } from '@common/types/DefaultProps';
import { Route } from '@web/routing';
import { getNextWebServerSidePropsWrapper, NextWebServerSideProps } from '@web/routing/serverSideProps';
import { Auth, FlowType, getServerRefererUrl, Props } from '@web/templates/Signin/Auth';

export const prepareProps: GetServerProps<Props> = async ({
    contextData,
    context: serverContext,
}): GetPropsReturn<Props> => {
    const serverRefererUrl = getServerRefererUrl(contextData, serverContext);

    return {
        serverRefererUrl,
        flowType: FlowType.STANDALONE,
    };
};

export const getServerSideProps: NextWebServerSideProps = getNextWebServerSidePropsWrapper(
    prepareProps,
    Route.Signin,
);

export default Auth;
